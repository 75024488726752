/* master.css - Global styles */
body {
  font-family: 'Roboto', sans-serif;
  font-weight: 500; /* Set the font weight to 500 */
  background-color: #ffffff;
  color: #333;
  margin: 0 auto; /* Center the body content */
  padding: 0;
  text-align: center; /* Center all text by default */
}




h1 strong {
  font-size: 3.5rem;
}

h3 {
  font-size: 2.5rem;
  font-weight: normal; /* Less bold text */

}

.pages {
  padding-top: 100px; /* Adjust this value based on the actual height of your navbar */
}

a {
  color: #333;
  text-decoration: none;
}

/* Button Styles - Base styles for buttons and links within buttons */
.btn, .button, button, .buttonSecondary, .button a, .buttonSecondary a {
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Apply transition to both background-color and color */
  color: #fff;
  border: none;
  text-align: center;
  display: inline-block;
  margin: 0 auto; /* Center buttons */
  padding: 10px 25px; /* Unified padding for all buttons and links */
  background-color: #5851d1; /* Default background color */
}

/* Specific background color for .buttonSecondary */
.buttonSecondary, .buttonSecondary a {
  background-color: #9d9ca0;
}

/* Hover styles for all buttons and links within buttons */
.btn:hover, .button:hover, button:hover, .buttonSecondary:hover, .button a:hover, .buttonSecondary a:hover {
  background-color: #0a029e;
}

/* Media Query for Webpage (Desktop) View */
@media (min-width: 768px) {
  body, a {
    font-size: 18px;
  }

  .btn, .button, button, .buttonSecondary, .button a, .buttonSecondary a {
    font-size: 21px; /* Unified font size for desktop */
  }
}

/* Utility classes, resets, and other global styles */

/* Additional styles for Home component layout */
.MainTitle h1, .MainTitle p {
  text-align: center; /* Center MainTitle h1 and p */
}

.MainTitle h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.MainTitle p {
  font-size: 1.2rem;
  color: #666;
}

.button a, .subtleLink a, .discreteLink a, .messengerButton a {
  padding: 10px 25px;
  border-radius: 30px;
  background-color: #5851d1;
  color: inherit; /* Ensure text color is inherited */
  display: inline-block;
  text-align: center;
}

.button a:hover, .messengerButton a:hover {
  background-color: #0a029e;
}

.subtleLink a, .discreteLink a {
  background-color: transparent;
  color: #767676;
  text-decoration: underline;
}

.subtleLink a {
  font-size: 18px;
}

.discreteLink a {
  font-size: 16px;
}

.messengerButton a {
  background-color: #5CAB7D;
}
@media (max-width: 767px) {
  body {  
    margin: 0; /* Removes left and right margin on mobile */
    padding: 0; /* Removes left and right padding on mobile */
  }

  .pages {
    padding-top: 70px; /* Adjust this value based on the actual height of your navbar */
    padding-left: 0;
    padding-right: 0;
  }

  .button a, .subtleLink a, .discreteLink a, .messengerButton a {
    padding: 8px 0; /* Removes left and right padding on mobile */
    border-radius: 30px;
    color: inherit; /* Ensure text color is inherited */
    display: inline-block;
    text-align: center;
  }

  h1 strong {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: normal; /* Less bold text */
  }
}
  
  



.MainTitle img {
  width: calc(100% - 70px); /* Adjusted width to account for padding */
  height: auto; /* Maintain aspect ratio */
  margin: 0 auto; /* Corrected to center the image */
  display: block; /* Ensure the image is a block-level element */
  padding: 10px; /* Adds padding around the image */
}

.MainTitle h1 {
  font-size: 2.5rem;
  margin-bottom: 0.05rem; /* Reduced margin-bottom for h1 */
}

.MainTitle p {
  font-size: 1.2rem;
  color: #666;
  margin-top: 0.85rem; /* Added margin-top for p to control space between h1 and p */
  margin-bottom: 0.85rem; /* Added margin-top for p to control space between h1 and p */
  padding: 0; /* Ensure no padding is adding extra space inside p */
}
