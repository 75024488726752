.centered-text {
  color: lightgrey;
  text-align: center;
  font-size: 40px;
}

.hideOnMobile {
  display: none;
}

.messenger {
  display: grid;
  grid-template-columns: 1fr 2fr; 
  height: 90vh;
  width: 100vw;
}

.chatMenu {
  border: none; /* No border on mobile */
  overflow: auto;
  height: calc(95vh - 10px); 
  width: calc(95vw - 10px); 
}

.chatBox {
  border: 1px solid rgb(155, 155, 155);
  overflow: auto;
  height: calc(95vh - 10px); 
  width: calc(100vw); 
}

.chatProfile {
  border: 1px solid rgb(155, 155, 155);
  overflow: auto;
  height: calc(95vh - 10px); 
  width: calc(95vw - 10px); 
}

.chatMenuWrapper, .chatBoxWrapper, .chatProfileWrapper {
  padding: 0px; 
  height: calc(100%); 
  width: calc(100%); 
  overflow: scroll; 
  flex-grow: 1; 
} 

@media (min-width: 768px) {
  .chatMenu, .chatBox, .chatProfile {
    display: block; 
  }
  
  .messenger {
    display: flex;
    flex-direction: row;
  }
  
  .hideOnDesktop {
    display: none;
  }
  
  .chatMenu {
    border: 1px solid rgb(155, 155, 155); /* Add border back on desktop */
    width: 250px; /* Fixed width */
  }
  .chatBox {
    flex: 2; /* Take up 2/3 of the remaining space */
  }
  .chatProfile {
    flex: 1.1; /* Take up 1/3 of the remaining space */
  }
}

