.chatBoxInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f8;
  border-top: 1px solid #d1d1d5;
  border-bottom: 1px solid #d1d1d5;
  position: sticky;
  bottom: 0;
}

.chatMessengerInput {
  width: 90%; /* Adjust the width as needed */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f4f4f8;
  border-top: 1px solid #d1d1d5;
  border-bottom: 1px solid #d1d1d5;
}

.chatMessengerInputTextArea {
  width: 80%; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
  padding: 10px 15px;
  border: 1px solid #d1d1d5;
  border-radius: 4px;
  resize: none;
  font-size: 14px;
  line-height: 1.5;
  height: 50px; /* Adjust the height as needed */
  outline: none;
}

.chatMessengerInputTextArea:focus {
  border-color: #007bff;
}

.chatSubmitButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.2s;
  margin-left: 10px;
}

.chatSubmitButton:hover {
  background-color: #0056b3;
}