.location-form {
  max-width: 600px; /* Set the maximum width of the form */
  margin: 0 auto; /* Center the form within its parent */
  padding: 20px; /* Adjust padding as needed */
  background-color: #f5f5f5;
  border-radius: 5px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}


.location-form * {
  max-width: 100%; /* Limit width to prevent overflow */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}


.location-form strong {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

#localArea {
  min-height: 2.5em; /* Adjust this value as needed to achieve the desired height */
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #f7f7f7;;
  border-radius: 5px;
  height: 25px;
  margin-bottom: 10px;
  text-align: left;
}

.location-form .autocomplete-container {
  display: block;
}

.location-form .autocomplete-input, .location-form .autocomplete-dropdown {
  min-height: 3.5em; /* Adjust this value as needed to achieve the desired height */
  width: 100%;
  padding: 10px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.location-form .button-container {
  display: flex;
  justify-content: left; 
  gap: 10px; 
} 

.location-form label {
  display: block; /* Ensure labels are block-level for better control */
  margin-bottom: 10px; /* Space between label and input set to 10px */
  font-weight: 600; /* Make label text bold */
  color: #5a5a5a; /* Dark color for label text for better readability */
  text-align: left; /* Align the text to the left */
  transition: color 0.3s; /* Smooth transition for color changes */
}

.location-form label:hover {
  color: #5851d1; /* Change label text color on hover for interactivity */
}

.location-form input[type="text"],
.location-form input[type="date"] {
  min-height: 30.5em; /* Adjust this value as needed to achieve the desired height */
   width: 100%; /* Full width inputs */
  margin-bottom: 25px; /* Add 25px space above each input */
  padding: 12px; /* Padding inside inputs */
  border: 2px solid #ccc; /* Light border for inputs */
  border-radius: 6px; /* Slightly rounded corners for inputs */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  transition: border-color 0.3s; /* Smooth transition for border color changes */
}



.location-form input[type="text"]:focus,
.location-form input[type="date"]:focus {
  min-height: 30.5em; /* Adjust this value as needed to achieve the desired height */
  border-color: #5851d1; /* Change label text color on hover for interactivity */
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 2px rgba(255, 105, 180, 0.2); /* Add a glow effect on focus */
}

@media only screen and (max-width: 375px) {
  .location-form {
    max-width: 300px; /* Ensure the form is no wider than 300px */
    padding: 10px;
}
  .location-form strong {
      font-size: 18px;
  }

  .location-form .autocomplete-input, .location-form .autocomplete-dropdown {
      padding: 5px;
  }

  .location-form button {
      padding: 5px 10px;
  }

  .h2 {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 20px;
  }

  .location-form .autocomplete-input, .location-form .autocomplete-dropdown {
      width: 100%;
      padding: 10px;
      height: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 10px;
  }
}