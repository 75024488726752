.photo-upload-wrapper {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  background-color: #f5f5f5; /* Your chosen color */
  max-width: 600px; /* Maximum width of the background */
  margin: 0 auto; /* Center the wrapper div itself */
  padding: 20px; /* Optional: Adds some space inside the wrapper */
}

.photo-upload-container {
    max-width: 600px;
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  .photo-upload-square {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .file-input {
    display: none;
  }
  
  .file-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .upload-placeholder {
    font-size: 24px;
    color: #999;
  }
  
  .profile-photo-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .upload-button {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }.photo-upload-square {
    position: relative;
    overflow: visible; /* Allow the delete icon to show outside the box */
  }
  .delete-icon {
    position: absolute;
    top: -15px; /* Adjust based on your layout */
    right: -15px; /* Adjust based on your layout */
    width: 30px; /* Diameter of the round background */
    height: 30px; /* Diameter of the round background */
    background-color: white; /* Background color of the circle */
    border-radius: 50%; /* Makes the background round */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Optional: adds a shadow for depth */
    color: #FF0000; /* Color of the "X" icon */
  }
 
  .photo-upload-wrapper h2 {
    text-align: left;
    align-self: flex-start; /* Aligns this item to the start of the flex container */
    width: 100%; /* Ensures the h2 element spans the full width of the container */
  }