/* Specific styles for the CommunityDetails component */

#boxDisplayCommunityBox {
  background-color: #F7F7F7; /* Light gray background */
  border-radius: 10px; /* Rounded corners for the box */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px; /* Padding inside the box */
  max-width: 400px; /* Maximum width of the box */
  margin: 20px auto; /* Center the box with automatic margins */
}


#boxDisplayCommunityDetails img {
  max-width: 200px; /* Increase the max-width to make the image larger */
  margin: 0 auto 10px; /* Center the image and add margin below */
}

#boxDisplayCommunityTitle {
  margin: 10px 0; /* Add margin above and below the title */
  font-weight: bold; /* Bold title text */
  font-size: 1.5rem; /* Font size for the summary */

}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 4px;
}

.line {
  height: 1px; /* Sets the thickness of the line */
  background-color: #dddddd; /* Sets the color of the line */
  width: 100%; /* Ensures the line spans the full width of its container */
  margin:  0; /* Adds some space above and below the line */
}

#boxDisplayCommunityInfo {
  display: flex; /* Use flexbox layout */
  justify-content: space-between; /* Space between type and location */
}

#boxDisplayCommunityLocation, #boxDisplayCommunityType {
  font-size: 1.2rem; /* Smaller font size for these elements */
}

#boxDisplayCommunitySummary {
  font-size: 1.2rem; /* Font size for the summary */
  color: #666; /* Darker grey color for the text */
  margin: 10px 0; /* Margin above and below the summary */
  font-weight: normal; /* Less bold text */
}

#boxDisplayCommunityTagsContainer {
  display: flex; /* Use flexbox layout */
  flex-wrap: wrap; /* Allow tags to wrap onto the next line */
  justify-content: flex-start; /* Align tags to the start of the container */
  gap: 10px; /* Space between tags */
  margin: 10px 0; /* Margin above and below the tags container */
}

#boxDisplayCommunityTagsContainer .tag {
  border: 1px solid #47456B; /* Border color to match the buttons */
  border-radius: 15px; /* Rounded corners for tags */
  padding: 5px 10px; /* Padding inside the tags */
  font-size: 0.8rem; /* Smaller font size for tags */
  background-color: #E7E7E7; /* Light grey background for tags */
  color: #333; /* Text color */
}

#boxDisplayCommunityJoinButton, #boxDisplayCommunityMoreInfoButton {
  width: 100px; /* Fixed width for buttons */
  margin: 10px 5px; /* Margin around the buttons */
  background-color: #47456B; /* Background color for join button */
  color: #fff; /* Text color for buttons */
  border: none; /* No border for buttons */
  border-radius: 25px; /* Rounded corners for buttons */
  padding: 10px 20px; /* Padding inside the buttons */
  display: inline-block; /* Display buttons inline */
  text-align: center; /* Center text inside buttons */
}

#boxDisplayCommunityMoreInfoButton {
  background-color: #fff; /* Background color for more info button */
  color: #47456B; /* Text color to match the join button */
  border: 1px solid #47456B; /* Border to match the join button */
}

#boxDisplayCommunityMoreInfoButton:hover {
  background-color: #acaad8; /* Hover color for more info button */
  color: #fff; /* Text color on hover */
}