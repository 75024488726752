#signup-heading {
  margin-bottom: 20px; /* Increase bottom margin */
}

#checkbox-signup + a {
  text-decoration: underline;
}

.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Added this line */
}

.form-heading {
  margin-top: 0;
  text-align: center;
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  margin: 20px 0; /* Add top and bottom margin */

  font-weight: bold;
}

#checkbox-signup {
  margin-right: 10px;
  margin-top: -40px;
}

.form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box; /* Added this line */
}

.form-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #5851d1;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #0a029e;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.terms-message {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.social-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.social-button {
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  background-color: #6531b8;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
}

.social-button:hover {
  background-color: #ddd;
}

@media (max-width: 480px) {
  .form-container {
    max-width: 100%;
    padding: 10px;
  }

  .form-input {
    padding: 8px;
  }

  .form-button {
    padding: 8px 16px;
  }
}

form.signup input[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

form.signup input[type="submit"]:hover {
  background-color: #0056b3;
}