.headerContainer {
  display: flex; /* Enables Flexbox layout */
  align-items: center; /* Vertically centers the items in the container */
  justify-content: start; /* Aligns items to the start of the container */
  gap: 5px; /* Adds space between each item */
}
 
#chatTitle {
  font-size: 16px; /* Adjust font size as needed */
  margin: 0 10px; /* Add margin to the left and right */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add an ellipsis if text is too long */
}

#backButton {
  border: none;
  cursor: pointer;
  font-size: 1.7rem; /* Adjust size as needed */
  background-color: transparent; /* Remove button color */
  color: grey; /* Make the arrow grey */
}

/* Hide the button on desktop screens */
@media (min-width: 1024px) { /* Adjust the breakpoint as needed */
  #backButton {
    display: none;
  }
}