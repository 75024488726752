
#community-title {
  margin-top: 20px; /* Increase top margin */
  font-size: 1.7rem;
}

#community-title-join{
  font-size: 3rem !important; /* Set the font size for this h3 */
}


#community-second-title {
  font-size: 1.7rem; /* Set the font size for this h3 */
}



.community-signup-form {
  text-align: center; /* Center the content */
  padding: 10px; /* Reduce padding around the form */
}


.community-signup-form h2, .community-signup-form h3, .community-signup-form h4 {
  margin: 5px 0; /* Reduce margin top and bottom */
  padding: 0; /* Remove padding */
}

.community-signup-form h4 {
  font-size: 1.5rem; /* Set the font size for h4 */
  color: #333; /* Set the color for h4 */
}

.image-container {
  width: 200px;
  height: 200px;
  overflow: hidden;
  margin: auto; /* Center the container */
}

.community-signup-form-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.community-signup-form h2 {
  font-size: 2.5rem; /* Set the font size for h2 */
  color: #333; /* Set the color for h2 */
}

.community-signup-form h3 {
  font-size: 1.5rem; /* Set the font size for h3 */
  color: #333; /* Set the color for h3 */
}

/* Media Query for Mobile View */
@media (max-width: 767px) {

  .image-container {
    width: 170px;
    height: 80px;
    overflow: hidden;
    margin: auto; /* Center the container */
  }
  
  .community-signup-form h2 {
    font-size: 2rem; /* Reduce the font size for h2 on mobile */
  }

  .community-signup-form h3 {
    font-size: 1.2rem; /* Reduce the font size for h3 on mobile */
  }

  #h5-form {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 1vh;
  }

  .community-signup-form-image {
    width: 70%; /* Reduce the image size a bit for mobile */
  }
}