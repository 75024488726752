.profile-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #f0f0f0;
  border-radius: 10px;
  width: 100%;
  max-width: none;
  margin: 0 auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.profile-image-container {
  position: relative;
  width: 100%;
  max-height: 250px;
  margin: 0px auto 0 auto;
  overflow: hidden; /* Added this line */
}

.profile-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.arrow-left, .arrow-right {
  position: absolute;
  top: 50%;
  color: #000;
  cursor: pointer;
  font-size: 2rem;
  opacity: 0;
  transition: opacity 0.3s;
}

.arrow-left {
  left: 10px; /* adjust as needed */
}

.arrow-right {
  right: 10px; /* adjust as needed */
}

.profile-image-container:hover .arrow-left,
.profile-image-container:hover .arrow-right {
  opacity: 1;
}

.user-info {
  text-align: left;
  margin: 1px 0;
  line-height: 1.5; /* Adjust this value as needed */
  padding: 15px;
}

.user-profile {
  margin: 0;
  text-align: left;
  margin-top: 0px;
}

#user-bio {
  word-wrap: break-word;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer */
}

@media screen and (max-width: 600px) {
  .icon-container {
    display: block;
  }
}

@media screen and (min-width: 601px) {
  .hide-on-desktop {
    display: none;
  }
}