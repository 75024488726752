#navBarHeader {
    /* background-color: #ffffff; */
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.navTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
}

.navTitle h1 {
    font-size: 1.8rem;
    color: #333;
    margin: 0;
    font-weight: 600;
}

nav {
    display: flex;
    align-items: center;
}

.menu-icon {
    display: none;
    font-size: 2rem;
    cursor: pointer;
}

.menu {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.menu-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    z-index: 1001;
    padding-top: 105px;
}

.close-icon {
    position: fixed;
    top: 40px;
    right: 40px;
    cursor: pointer;
    font-size: 2rem;
    z-index: 1002;
}

.navButton {
    background-color: #47456B;
    color: #fff;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 250px;
    box-sizing: border-box;
    margin: 10px auto;
    text-decoration: none; /* No underline */
}

.navButtonText {
    color: inherit; /* Inherit the color from the parent */
    text-decoration: none; /* No underline */
    display: block; /* Take up the full space of the button */
}

.navButton:hover {
    background-color: #acaad8; /* Uniform button hover background */
    color: #fff; /* Ensure text color changes uniformly */
}

@media (max-width: 768px) {
    .menu-active {
        display: none;
    }

    .menu-active.show {
        display: flex;
        flex-direction: column;
    }

    .menu-icon {
        display: block;
    }

    .navButton {
        padding: 10px 20px;
        font-size: 20px;
        width: 80%;
        max-width: 250px;
        display: block;
        margin: 10px auto;
    }
}

@media (min-width: 769px) {
    .close-icon, .menu-icon {
        display: none;
    }

    .menu-active {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: auto;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
    }

    .menu {
        display: none;
    }

    .navButton {
        padding: 10px 20px;
        font-size: 20px;
        width: auto;
        display: inline-block;
        margin: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 1px solid transparent;
        color: white;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
    }

    s.navButton, .navButton a {
        color: #fff;
        text-decoration: none;
    }
    

    header .navTitle h1 {
        padding-left: 0px;
        text-align: left;
    }

    header .close-icon {
        top: 20px;
        right: 20px;
    }
}
@media (max-width: 768px) {

    .menu-active {
        display: none;
    }
    /* Show the menu-active div when the menu is open */
    .menu-active.show {
        display: flex;
        flex-direction: column;
        /* Other styles for the active menu */
    }

    .menu-icon {
        display: block;
    }
    header .menu-icon {
        padding: 15px; /* Adds padding around the hamburger icon */
        position: relative; /* Ensures the icon is positioned within the header */
        z-index: 1003; /* Ensures the icon is above other elements */
    }

    .menu {
        display: none;
    }

    .navButton {
        padding: 10px 20px;
        font-size: 20px;
        width: 80%;
        max-width: 250px;
        display: block;
        margin: 10px auto;
    }

    s.navButton, .navButton a {
        color: #fff;
        text-decoration: none;
    }
    

    header .navTitle h1 {
        padding-left: 0px; /* Adds padding to the left of the title */
        text-align: left; /* Aligns the title to the left */
    }

    header .close-icon {
        top: 20px; /* Adjusts the top position */
        right: 20px; /* Adjusts the right position */
    }
}