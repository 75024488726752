.conversation {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.conversation:hover {
  background-color: rgb(245, 243, 243);
}

/* Target images with a src attribute and apply desired styles */
.conversationImg[src] {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

/* Hide or alter the appearance of images without a src attribute */
.conversationImg:not([src]), .conversationImg[src=""] {
  display: none; /* Hide the element */
  /* Alternatively, you can set border to none if you don't want to hide it */
  /* border: none; */
}
.conversationName {
  font-weight: 500;
}
.newMessageAlertIcon {
  width: 0.5cm; 
  height: 0.5cm; 
  border-radius: 50%; 
  background-color: #1877f2;
  display: inline-block; 
  margin-left: 10px; 
  vertical-align: middle;
}
@media screen and (max-width: 768px) {

}
