.dynamic-form {
  max-width: 600px;
  margin: 20px auto;
  padding: 2%;
  background-color: #f5f5f5;
  border-radius: 8px;
}


.dynamic-form h1,
.dynamic-form h2 {
  text-align: center;
  margin-bottom: 15px;
}
#profileHeader {
  margin-bottom: 50px;
  font-size: 2em;
}

.dynamic-form p
 {
  text-align: left;
  margin-bottom: 15px;
}

.dynamic-form label,
.dynamic-form .dynamic-form__field > label {
  display: block;
  margin-bottom: 10px;
  margin-top: 30px;
  font-weight: 600;
  font-size: 25.5px;
  color: #5a5a5a;
  text-align: left;
  transition: color 0.3s;
}



.dynamic-form label:hover {
  color: #5851d1;
}

.dynamic-form input[type="text"],
.dynamic-form input[type="date"],
.dynamic-form select,
.dynamic-form textarea.mediumBoxSize,
.dynamic-form .dynamic-form__field > p {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  width: 100%;
  margin-bottom: 25px;
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.dynamic-form textarea.mediumBoxSize {
  height: 200px;
}

.dynamic-form input[type="text"]:focus,
.dynamic-form input[type="date"]:focus,
.dynamic-form select:focus,
.dynamic-form textarea.mediumBoxSize:focus {
  border-color: #5851d1;
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 105, 180, 0.2);
}

.dynamic-form button:hover {
  background-color: #45a049;
}

.dynamic-form .dynamic-form-checkboxes,
.dynamic-form .dynamic-form__field {
  margin-bottom: 10px;
}


.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: -8px;
}

button#saveButton, button#cancelButton {
  margin: 0 15px;
  padding: 8px 16px; /* Adjust padding for sizing */
}

button#cancelButton {
  background-color: #c4c4c4; /* Grey background for the Cancel button */
  border: 1px solid #a0a0a0; /* Optional: adds a border to the Cancel button */
}

button#saveButton:hover {
  background-color: #45a049; /* Green background on hover for Save button */
}

button#cancelButton:hover {
  background-color: #a8a8a8; /* Slightly darker grey on hover for Cancel button */
}
.dynamic-form__field > .dynamic-form__checkbox-label {
  font-size: 16px;
  color: #333;
  font-weight: 900;
  margin-right: 10px;

}
.dynamic-form__checkbox-label#checkBoxLabel {
  margin-top: 40px;
}

.dynamic-form__checkbox-label#checkBoxItems {
  font-size: 17px;
  margin-left: 20px;
  margin-top: 3px;
  margin-bottom: 3px;
}

/* Custom slider styles */
.switch,
.dynamic-form .dynamic-form__field > .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input[type="checkbox"],
.dynamic-form .dynamic-form__field > .switch input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider,
.dynamic-form .dynamic-form__field > .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before,
.dynamic-form .dynamic-form__field > .switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider,
.dynamic-form .dynamic-form__field > .switch input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider,
.dynamic-form .dynamic-form__field > .switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before,
.dynamic-form .dynamic-form__field > .switch input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round,
.dynamic-form .dynamic-form__field > .switch .slider.round {
  border-radius: 34px;
}

.slider.round:before,
.dynamic-form .dynamic-form__field > .switch .slider.round:before {
  border-radius: 50%;
}

.dynamic-form .dynamic-form__field input[type="checkbox"] {
  transform: scale(1.3);
  margin-top: 5px;
  position: relative;
}

input[type="checkbox"] {
  transform: scale(1.5);
  margin-right: -95px;
  vertical-align: middle;
  margin-bottom: -40px;
}

@media only screen and (max-width: 375px) {
  .dynamic-form .dynamic-form__field {
    flex-direction: column;
    align-items: flex-start;
  }



  .dynamic-form .dynamic-form__field > label,
  .dynamic-form .dynamic-form__field > p {
    width: 100%;
  }


}