.chatBoxComponent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.chatBoxHeader {
  display: flex; /* Enables Flexbox */
  justify-content: center; /* Centers children horizontally */
  align-items: center; /* Centers children vertically */
  padding: 20px;
  border-bottom: 1px solid rgb(155, 155, 155); /* Add grey border to the bottom */
  border-top: 1px solid rgb(155, 155, 155); /* Add grey border to the bottom */

  height: 50px; /* Set a fixed height to ensure vertical centering */
  overflow: hidden; /* Hide overflow */
}

.chatBoxMessages {
  flex-grow: 1;
  overflow-y: auto;
}

.chatBoxInput {
  min-height: 100px;
  max-height: 200px;
  overflow: hidden;
  border-top: 1px solid rgb(155, 155, 155); /* Add grey border to the top */
}