.home-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  padding: 20px;
}

#imageContainer img {
  display: block;
  margin: 0 auto;
  max-height: 100vh; /* Sets the maximum height to 100% of the viewport height */
  height: auto; /* Allows the height to be automatically determined based on the aspect ratio */
  width: auto;
}

#afterImgPositioning {
  position: relative;
  top: -25px;
}

@media screen and (min-width: 768px) {
  .home-content-container {
    width: 40%; /* Sets the width to 100% of the viewport width */
    margin: 0 auto;
    padding: 20px;
  }
}

@media screen and (max-width: 480px) {
  .home-content-container {
    padding: 10px;
  }
}
/*
.home2 {s
  display: flex;
  flex-direction: column; 
  align-items: center; 
  gap: 20px; 
}

.introhome2 h1, .introhome2 p {
  text-align: center;
}

.getstartedbtn, .moreinfo2btn, .NewCommunityEntry, .loginbtn, .Profile, .messengerButton {
  cursor: pointer;
  padding: 12px 28px;
  border-radius: 25px; /
  color: #fff; 
  text-decoration: none; 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  text-align: center;
  background: var(--primary); /
  margin: 10px 0; 
}

.getstartedbtn {
  background: #4CAF50; /
}

.getstartedbtn:hover, .moreinfo2btn:hover, .NewCommunityEntry:hover, .loginbtn:hover, .Profile:hover, .messengerButton:hover {
  background: #1976D2; 
  transform: scale(1.05); 
  transition: background 0.3s, transform 0.3s;/
}

/* .home2 {
    display: flexbox;
    grid-template-columns: 3fr 1fr;
    gap: 100px;
  }
  .introhome2 h1 {
    text-align: center;
  }
  .introhome2 p {
    text-align: center;
  }
  .moreinfo2btn {
    align-self:center;
    cursor: pointer;
    background: #f1f1f1;
    padding: 12px 28px;
    border-radius: 90%;
    color: #333;
  }
  .NewCommunityEntry {
    align-self:center;
    cursor: pointer;
    background: #f1f1f1;
    padding: 12px 28px;
    border-radius: 90%;
    color: #333;
  }
  .getstartedbtn {
    align-self: center;
    cursor: pointer;
    background: #e07676;
    padding: 12px 28px;
    border-radius: 50%; 
    color: #e07676;
    width: 70px; 
    height: 50px; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
*/
