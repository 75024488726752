#boxDisplayCommunityTagsContainer {
  display: flex; /* Use flexbox layout */
  flex-wrap: wrap; /* Allow tags to wrap onto the next line */
  justify-content: flex-start; /* Align tags to the start of the container */
  gap: 10px; /* Space between tags */
  margin: 10px 0; /* Margin above and below the tags container */
}

#boxDisplayCommunityTagsContainer .tag {
  border: 1px solid #47456B; /* Border color to match the buttons */
  border-radius: 15px; /* Rounded corners for tags */
  padding: 5px 10px; /* Padding inside the tags */
  font-size: 0.8rem; /* Smaller font size for tags */
  background-color: #E7E7E7; /* Light grey background for tags */
  color: #333; /* Text color */
}

/* Existing styles for larger screens */

/* Media query for tablet devices */
@media (max-width: 768px) {
  .MainTitle h1 {
    font-size: 1.5rem; /* Adjust font size */
  }

  #imageContainer img {
    width: 100%; /* Make images responsive */
    height: auto;
  }

  .home-content-container {
    padding: 20px; /* Adjust padding */
  }

  #boxDisplayCommunityTagsContainer .tag {
    font-size: 0.7rem; /* Adjust tag font size */
  }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .MainTitle h1 {
    font-size: 1.2rem; /* Further reduce font size for mobile */
  }

  .home-content-container {
    padding: 10px; /* Reduce padding for mobile */
  }

  #boxDisplayCommunityTagsContainer {
    justify-content: center; /* Center tags for better visual */
  }

  #boxDisplayCommunityTagsContainer .tag {
    font-size: 0.6rem; /* Further reduce tag font size */
  }

  /* Adjust button sizes and text alignment */
  .button button, .button a {
    width: 100%; /* Full width buttons */
    text-align: center; /* Ensure text is centered */
    padding: 10px 0; /* Adjust padding */
  }
}