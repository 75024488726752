.custom-accordion-summary {
  display: flex;
  align-items: center; /* Ensures vertical centering of children */
  justify-content: flex-start; /* Aligns children to the start, ensuring no unnecessary space between them */
}

.summary-content {
  /* Allows the content to take necessary space, but not grow to fill all available space */
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto; /* Adjusts the base size according to the content size */
  text-align: left; /* Aligns the text to the left */
}

.summary-image {
  /* Prevents the image container from growing or shrinking unnecessarily */
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto; /* Adjusts the base size according to the content size */
  display: flex;
  align-items: center; /* Center the image vertically */
  margin-left: 10px; /* Adds space between the text and the image, adjust as needed */
}

.summary-image-hidden {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s .8s, opacity .8s ease-in-out;
}

img[id^="photo-icon-"] {
  max-height: 35px;
  /* Adjusts the image size, ensuring it fits well next to the text */
}

.accordion-container .MuiAccordionSummary-root:hover {
  background-color: #f7f7f7;
}
.accordion-container .MuiAccordion-root.Mui-expanded {
  background-color: #f7f7f7;
}


@media (min-width: 768px) {
  /* Styles for desktop view */
  .accordion-container {
    max-width: 800px; /* Set the maximum width to 100px for desktop */
    margin: auto; /* Center the accordion in the middle of its parent */
  }
}

