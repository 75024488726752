/* ProfileAll.css */


/* ProfileAll.css */

.alert-info {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: left !important;
}

.alert-list {
  font-size: 14px; /* adjust as needed */
  margin-bottom: 20px; /* adjust as needed */
  padding-left: 40px; /* adjust as needed */
  text-indent: 27px; /*  adjust as needed */
  padding-left: 10px; /* adjust as needed */
}

.alert-info li {
  margin-bottom: 20px; /* adjust as needed */
}
/* rest of your CSS code */

#checkbox-container {
  display: flex;
  align-items: center;
}

#checkbox-container .alert-label {
  margin-right: 10px;
  font-weight: bold;
}

#checkbox-container .alert-input {
  width: auto;
  padding: 0;
  margin-bottom: 15px;
  border: none;
}

#alert-heading {
  margin-bottom: 20px;
}

.alert-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.alert-heading {
  margin-top: 0;
  text-align: center;
  margin-bottom: 20px;
}

.alert-label {
  margin-right: 10px;
  font-weight: bold;
}

.alert-input {
  width: auto;
  padding: 0;
  margin-bottom: 15px;
  border: none;
}

.alert-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #5851d1;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.alert-button:hover {
  background-color: #0a029e;
}

.alert-message {
  color: red;
  margin-bottom: 10px;
}

.alert-info {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  .alert-container {
    max-width: 100%;
    padding: 10px;
  }

  .alert-input {
    padding: 8px;
  }

  .alert-button {
    padding: 8px 16px;
  }
}