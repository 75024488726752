.profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.profile.interconnecting {
  background-color: #e9e9f9;
}

.profileContent {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.profileImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.profileName {
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.profileBio {
  font-size: 14px;
  color: #666;
  width: 100%;
}

.introduceButton {
  padding: 5px 10px;
  margin-top: 5px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.profileAge {
  font-size: 14px;
  color: #555;
  margin: 2px 0;
}

.profileLocation {
  font-size: 14px;
  color: #555;
  margin: 2px 0;
}

.not-bold {
  font-weight: normal;
}