/* MoreInfo.css */

.more-info-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 2rem;
  }
  
  .more-info-main-title {
    max-width: 800px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 2rem;
  }
  
  .more-info-main-title h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .more-info-image-container {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .more-info-image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    max-height: 300px; /* Ensures the image fits nicely */
  }
  
  .more-info-after-img-positioning h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #555;
  }
  
  .more-info-after-img-positioning p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  .more-info-how-it-works {
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .more-info-how-it-works h4 {
    margin-bottom: 0.5rem;
  }
  
  .more-info-how-it-works ol {
    padding-left: 0;
    list-style: none;
    text-align: center;
  }
  
  .more-info-how-it-works li {
    margin-bottom: 1rem;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .more-info-how-it-works .number {
    margin-right: 0.5rem;
  }
  
  .more-info-button {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-decoration: none;
    margin-bottom: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .more-info-button:hover {
    background-color: #0056b3;
  }
  
  .more-info-button center {
    font-weight: bold;
  }
  
  .more-info-additional-info {
    margin-top: 1rem;
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  