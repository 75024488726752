/* New wrapper around the input and icon for better control */
.search-bar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%; /* Adjusted for desktop */
  margin: 0 auto;
}

/* Adjust the input wrapper to position the icon inside it */
.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

/* Adjust the input field to make room for the search icon and ensure it fits in its parent */
.react-autosuggest__input {
  width: 100%; /* Take the full width of its parent */
  padding: 15px 20px 15px 20px; /* Adjust padding to accommodate the icon */
  font-size: 1.25rem;
  border: 2px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Position the search icon inside the input on the right */
#search-icon {
  position: absolute;
  right: 10px; /* Position the icon inside the input field */
  cursor: pointer;
  color: #ccc;
  font-size: 1.25rem;
}

/* Adjust the container to have a relative position */
.react-autosuggest__container {
  position: relative;
  width: 100%; /* Ensure it matches the width of the input-wrapper */
}

/* Position the suggestions container directly below the input */
.react-autosuggest__suggestions-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0; /* Ensure the container stretches to match the input field's width */
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* Style the suggestions list */
.react-autosuggest__suggestions-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: 1px solid #aaa;
  border-top: none;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}

/* Style each suggestion */
.react-autosuggest__suggestion {
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
}

/* Highlighted suggestion style */
.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .search-bar-wrapper, .react-autosuggest__container {
    width: 100%; /* Full width on mobile devices */
  }
}